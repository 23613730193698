import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';

const StyledStatusBar = styled.ul.withConfig({
  componentId: "sg1670__sc-1djtgbz-0"
})(["background-color:", ";border-top:1px solid ", ";bottom:0;box-shadow:", ";color:", ";display:flex;font-size:", ";justify-content:center;list-style-type:none;margin:0;padding:0 ", ";position:sticky;z-index:1;"], tokens.color.white, tokens.color['neutral-3'], props => props.$shadow && tokens.shadow.default, tokens.color['neutral-7'], tokens['font-size'].sm, tokens.space.md);
const StyledStatusBarItem = styled.li.withConfig({
  componentId: "sg1670__sc-1djtgbz-1"
})(["align-items:center;display:none;padding:", " 0;text-align:center;&:last-child{display:flex;}", "{display:flex;padding:", " 0;& + &{border-left:1px solid ", ";margin-left:", ";padding-left:", ";}}"], tokens.space.xxs, media.md, tokens.space.xs, tokens.color['neutral-3'], tokens.space.md, tokens.space.md);

export { StyledStatusBar, StyledStatusBarItem };
