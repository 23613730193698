import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';
import { hexToRGBA } from '../utils/styles/hex-to-rgba.js';

/**
 * Duplicated in _divider.scss.
 */
const StyledDivider = styled.div.withConfig({
  componentId: "sg1670__sc-d64o0k-0"
})(["", ""], props => {
  if (props.$variant === 'vertical') {
    if (props.$noMargin) {
      return css(["background-color:", ";height:100%;margin-left:0;margin-right:0;width:1px;"], tokens.color['neutral-4']);
    } else {
      return css(["background-color:", ";height:100%;margin-left:", ";margin-right:", ";width:1px;"], tokens.color['neutral-4'], tokens.space.xl, tokens.space.xl);
    }
  } else if (props.$variant === 'inlineVertical') {
    return css(["background-color:", ";height:100%;margin:", " ", ";width:1px;"], tokens.color['neutral-6'], tokens.space.xxxs, tokens.space.xs);
  } else if (props.$variant === 'sidebar') {
    return css(["border:0;border-top:1px solid ", ";margin-bottom:", ";margin-top:", ";border-top-color:", ";margin-left:-", ";margin-right:-", ";", "{margin-left:-", ";margin-right:-", ";}", "{background-image:linear-gradient( to right,", " 0,", " ", " );border-top:0;height:1px;}"], tokens.color['neutral-4'], tokens.space.xl, tokens.space.xl, tokens.color['neutral-3'], tokens.space.md, tokens.space.md, media.lg, tokens.space.xl, tokens.space.xl, media.width(1920), hexToRGBA(tokens.color['neutral-3'], 0), tokens.color['neutral-3'], tokens.space.xl);
  } else if (props.$noMargin) {
    return css(["border:0;border-top:1px solid ", ";margin-bottom:0;margin-top:0;"], tokens.color['neutral-4']);
  } else {
    return css(["border:0;border-top:1px solid ", ";margin-bottom:", ";margin-top:", ";"], tokens.color['neutral-4'], tokens.space.xl, tokens.space.xl);
  }
});

export { StyledDivider };
