import React, { useState } from 'react'

import {
  Button,
  Collapsible,
  Collapsibles,
  Focus,
  Heading,
  Input,
  PlusIcon,
  Spacer
} from '@te-digi/styleguide'

import lorem from '../../lorem'

const FocusCollapsiblesExample = () => {
  const [shouldFocus, setShouldFocus] = useState(false)
  const [collapsibles, setCollapsibles] = useState([
    {
      id: 0,
      label: lorem({ words: 4 }),
      subLabel: lorem({ words: 6 }),
      isOpen: false
    },
    {
      id: 1,
      label: lorem({ words: 4 }),
      subLabel: lorem({ words: 6 }),
      isOpen: false
    }
  ])

  const onCollapsibleToggle = (id: number) => {
    setCollapsibles(
      collapsibles.map(item => ({
        ...item,
        isOpen: item.id === id ? !item.isOpen : item.isOpen
      }))
    )
  }

  const onAddClick = () => {
    setCollapsibles([
      ...collapsibles,
      {
        id: collapsibles.length,
        label: lorem({ words: 4 }),
        subLabel: lorem({ words: 6 }),
        isOpen: true
      }
    ])
    setShouldFocus(true)
  }

  return (
    <>
      <Collapsibles>
        {collapsibles.map((collapsible, index) => {
          const isLast = collapsibles.length === index + 1

          return (
            <Focus
              enabled={isLast && shouldFocus}
              key={collapsible.id}
            >
              <Collapsible
                bordered
                color="light"
                isOpen={collapsible.isOpen}
                label={
                  <Heading
                    level={3}
                    noMargin
                    size={4}
                  >
                    {collapsible.label}
                  </Heading>
                }
                onBlur={() => setShouldFocus(false)}
                onToggle={() => onCollapsibleToggle(collapsible.id)}
                subLabel={collapsible.subLabel}
              >
                <Input label="Mauris sed libero" />
              </Collapsible>
            </Focus>
          )
        })}
      </Collapsibles>
      <Spacer marginTop="lg">
        <Button
          iconLeft={<PlusIcon />}
          onClick={onAddClick}
        >
          Lisää uusi asia
        </Button>
      </Spacer>
    </>
  )
}

export { FocusCollapsiblesExample }
