import React from 'react'

import {
  Button,
  Buttons,
  Checkbox,
  CheckboxGroup,
  Col,
  Columns,
  Detail,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Input,
  Lead,
  Main,
  Message,
  MessageHeader,
  Paragraph,
  PostponeIcon,
  Row,
  SkipLink,
  Spacer,
  StatusBar,
  StatusBarItem,
  Textarea,
  Wrapper,
  XIcon
} from '@te-digi/styleguide'

import { FocusCollapsiblesExample } from '../FocusCollapsiblesExample'
import { HeaderExample } from '../HeaderExamples'
import { FooterExample } from '../FooterExample'

type ExampleProps = {
  className?: string
}

const FormPageExample = ({ className }: ExampleProps) => (
  <>
    <SkipLink />
    <Wrapper className={className}>
      <HeaderExample />
      <Columns layout="single">
        <Main>
          <div data-note="1">
            <Heading subHeading="Lisäotsikko">Lomakkeen otsikko</Heading>
            <Message attentionColor="secondary">
              <MessageHeader>
                <Heading
                  level={2}
                  noMargin
                  size={4}
                >
                  Tärkeä huomautettava asia, esim. lomakkeen tila tai määräpäivä
                </Heading>
              </MessageHeader>
            </Message>
            <Spacer marginBottom="xxl">
              <Lead>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                accumsan tortor nec est ornare bibendum. Etiam a scelerisque
                nisl, vel aliquam libero. Phasellus vehicula diam et placerat
                feugiat.
              </Lead>
            </Spacer>
          </div>
          <div data-note="2">
            <FormLayout>
              <FormLayoutItem
                header={
                  <>
                    <Heading
                      size={3}
                      level={2}
                    >
                      Ensimmäisen osion otsikko
                    </Heading>
                    <Paragraph>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla accumsan tortor nec est ornare bibendum. Etiam a
                      scelerisque nisl, vel aliquam libero. Phasellus vehicula
                      diam et placerat feugiat.
                    </Paragraph>
                  </>
                }
              >
                <Row>
                  <Col
                    xs={12}
                    md={6}
                  >
                    <FormGroup>
                      <Detail heading="Aliquam">
                        Sed congue augue vitae neque
                      </Detail>
                    </FormGroup>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                  >
                    <FormGroup>
                      <Detail heading="Erat volutpat">
                        Proin interdum maecenas massa
                      </Detail>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    label="Lorem ipsum dolor sit amet"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Textarea label="Nulla accumsan tortor" />
                </FormGroup>
                <CheckboxGroup
                  label="Phasellus vehicula diam"
                  onChange={() => {
                    // This is intentional
                  }}
                >
                  <Checkbox>Etiam a scelerisque nisl</Checkbox>
                  <Checkbox>Vel aliquam libero</Checkbox>
                </CheckboxGroup>
              </FormLayoutItem>
              <FormLayoutItem
                header={
                  <>
                    <Heading
                      size={3}
                      level={2}
                    >
                      Toisen osion otsikko
                    </Heading>
                    <Paragraph>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla accumsan tortor nec est ornare bibendum. Etiam a
                      scelerisque nisl, vel aliquam libero. Phasellus vehicula
                      diam et placerat feugiat.
                    </Paragraph>
                  </>
                }
                color="none"
              >
                <FocusCollapsiblesExample />
              </FormLayoutItem>
            </FormLayout>
          </div>
          <div data-note="3">
            <Divider />
            <Buttons align="center">
              <Button
                iconLeft={<XIcon />}
                variant="plain"
              >
                Keskeytä
              </Button>
              <Button
                iconLeft={<PostponeIcon />}
                variant="plain"
              >
                Jatka myöhemmin
              </Button>
              <Button color="secondary">Lähetä</Button>
            </Buttons>
          </div>
        </Main>
      </Columns>
      <StatusBar
        data-note="4"
        saveStatus="saved"
      >
        <StatusBarItem>Et ole vielä lähettänyt ilmoittautumista</StatusBarItem>
      </StatusBar>
      <FooterExample />
    </Wrapper>
  </>
)

export { FormPageExample }
